const I18NextHttpBackend = require("i18next-http-backend");

module.exports = {
  i18n: {
    defaultLocale: "pt",
    locales: ["pt", "en"],
  },
  backend: {
    loadPath: `${process.env.NEXT_PUBLIC_API}translations/{{lng}}`,
  },
  ns: ["translation"],
  defaultNS: "translation",
  serializeConfig: false,
  use: [I18NextHttpBackend],
  reloadOnPrerender: true
};
