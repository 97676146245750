import { transitionsDelay } from '../../styles/basicStyles';
import { breakpoints } from '../../styles/breakpoints';
import styled from 'styled-components';

export const CookieWrapper = styled.div<{ cookieBar: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.9);
  padding: 1.25rem;
  opacity: ${({ cookieBar }) => cookieBar ? 1 : 0};
  visibility: ${({ cookieBar }) => cookieBar ? 'visible' : 'hidden'};
  transition: opacity ${transitionsDelay} linear, visibility ${transitionsDelay} linear;

  @media ${breakpoints.md} {
    padding: 0.625rem;
  }
`;

export const CookieButton = styled.div<{ type: 'primary' | 'secondary' }>`
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
  padding: 0.6rem 1.5rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.desertStorm};
  background-color: ${({ theme }) => theme.colors.desertStorm};
  transition: background-color ${transitionsDelay} linear;
  cursor: pointer;
  text-align: center;

  & span {
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    background-color: transparent;

    & span {
      color: ${({ theme }) => theme.colors.desertStorm};
    }
  }

  &:first-child {
    margin-right: 0.5rem;
    border: 0.063rem solid #5747B2;
    background-color: #5747B2;

    &:hover {
      background-color: transparent;

      & span {
        color: #5747B2;
      }
    }
  }

  @media ${breakpoints.md} {
    padding: 0.4rem 1rem;
  }
`;
