import { FC, Fragment } from 'react';
import Head from 'next/head';

interface Params {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
}

const Metatags: FC<Params> = ({ title, description, keywords, image }) => {
  return (
    <Head>
      {!!title && (
        <Fragment>
          <title>{title}</title>
          <meta property='og:title' content={title} />
          <meta property='twitter:title' content={title} />
        </Fragment>
      )}
      {!!description && (
        <Fragment>
          <meta name='description' content={description} />
          <meta property='og:description' content={description} />
          <meta property='twitter:description' content={description} />
        </Fragment>
      )}
      {!!keywords && <meta name='keywords' content={keywords} />}
      {
        !!image ?
        <Fragment>
          <meta property='og:image' content={image}/>
          <meta property='twitter:image' content={image}/>
        </Fragment>
        :
        <Fragment>
          <meta property='og:image' content={`${process.env.NEXT_PUBLIC_URL}assets/intro.png`}/>
          <meta property='twitter:image' content={`${process.env.NEXT_PUBLIC_URL}assets/intro.png`}/>
        </Fragment>
      }
    </Head>
  );
};

export default Metatags;
