import { ReactNode, useState } from 'react';
import LoaderContext from './loader/loaderContext';

interface ContextProps {
  children: ReactNode;
}

const Context = ({ children }: ContextProps) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);

  return (
    <LoaderContext.Provider value={{ showLoader, setShowLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default Context;
