import { FC, ReactNode} from 'react';
import { LinkComponent } from './styles';

export interface LinkProps {
  children?: ReactNode;
}

export const Link: FC<LinkProps & any> = ({ children, ...props }) => {
  return (
    <LinkComponent { ...props} prefetch={false}>
      {children}
    </LinkComponent>
  );
};

export default Link;
