import { transitionsDelay } from '../../styles/basicStyles';
import { generateProps } from 'styled-gen';
import Link from 'next/link';
import styled from 'styled-components';

export const LinkComponent = styled(Link)`
  text-decoration: none;
  transition: opacity ${transitionsDelay} linear;

  &:hover {
    opacity: 0.6;
  }

  ${generateProps};
`;