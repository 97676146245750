import { useRouter } from 'next/router';
import {
  PageContainer,
  PageContent,
  ImageContainer,
  ImageContent,
  Image1,
  Image2,
  Title,
  Text,
  ButtonWrapper,
  Button
} from './styles';
import { FC } from 'react';
import Metatags from '../../components/metatags';

const ServerErrorPage: FC = () => {
  const router = useRouter();

  return (
    <PageContainer>
      <Metatags title='Down for maintenance || Codepoint' />
      <PageContent>
        <ImageContainer>
          <ImageContent>
            <Image1 src='/assets/server_error1.svg' alt='server error image'/>
            <Image2 src='/assets/server_error2.svg' alt='server error image'/>
          </ImageContent>
        </ImageContainer>
        <Title>Error</Title>
        <Text>
          Briefly down for maintenance, we'll be back soon.<br/>
          Sorry for the inconvenience.
        </Text>
        <ButtonWrapper>
          <Button onClick={() => router.reload()}>Try again</Button>
        </ButtonWrapper>
      </PageContent>
    </PageContainer>
  );
};

export default ServerErrorPage;
