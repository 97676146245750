import axios from 'axios';

export interface ApiResponse {
  success: boolean;
  data: any;
  title?: string;
  message?: string;
}

const mergeCommonHeaders = (rest: any) => {
  return {
    ...rest,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
};

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
  responseType: 'json'
});

client.interceptors.request.use(request => {
  const headers = mergeCommonHeaders(request?.headers?.common);
  
  if(headers && request?.headers) {
    request.headers.common = headers;
  }
  
  return request;
});

client.interceptors.response.use(
  success => {
    return success.data;
  },
  error => {
    return error.response
      ? {...error.response.data, success: false}
      : {success: false};
  }
);

export default client;