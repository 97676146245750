export interface ItemProps {
  name: string;
  url: string;
}

export const items: ItemProps[] = [
  {
    name: 'WORK',
    url: '/work',
  },
  {
    name: 'OUR_PROCESS',
    url: '/process',
  },
  {
    name: 'BLOG',
    url: '/blog',
  },
  {
    name: 'CAREERS',
    url: '/careers',
  }
];
