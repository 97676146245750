export const Purple = '#816CFF';
export const LightPurple = 'rgba(129, 108, 255, 0.12)';
export const LightBlue  = '#64FFE3';
export const Black = '#000000';
export const Black07 = 'rgba(0, 0, 0, 0.70)';
export const White = '#ffffff';
export const White80percent = 'rgba(255, 255, 255, 0.8)';
export const White90percent = 'rgba(255, 255, 255, 0.9)';
export const ChineseBlack = '#171717';
export const ChineseBlack40Percent = 'rgba(23, 23, 23, 0.4)';
export const CoolGrey = '#8D8DA3';
export const AntiFlashWhite = '#F2F3F3';
export const Gray = '#808080';
export const Cultured = '#F7F7F7';
export const LightSilver = '#D9D9D9';
export const Lavender = '#E6E2FF';
export const Ghost = '#C4C9D7';
export const SoftPeach = '#EFEFEF';
export const GreyGoose = '#D9CFCF';
export const BalticSea = '#252526';
export const Alabaster = '#FBFBFC';
export const DesertStorm = '#F7F8F9';
export const LightGrey = 'rgba(23, 23, 23, 0.30)';
export const DarkJungleGreen = '#222';
export const BlackCow = '#454545';
export const GreyCloud = '#B4B4B4';
export const RangoonGreen70Percent = 'rgba(23, 23, 23, 0.7)';
export const Dawn = '#A6A3A3';
export const Mercury = '#E2E4ED';
export const SilverChalice = '#ACACAC';
export const DisabledColor = 'rgba(0, 0, 0, 0.3)';
export const ErrorColor = '#DD425A';
export const SuccessColor = '#2BBC3A';
export const Heather= '#BBC2CC';
export const DawnPink = '#ECECEC';
export const MetallicSilver = '#A1A5BB';
export const DavyGrey = '#56585E';

export const PlaceholderColor = '#585858';
export const BorderColor = '#585858';

export const colors = { 
  purple: Purple,
  lightPurple: LightPurple,
  lightBlue: LightBlue,
  black: Black,
  black07: Black07,
  white: White,
  white80percent: White80percent,
  white90percent: White90percent,
  chineseBlack: ChineseBlack,
  coolGrey: CoolGrey,
  antiFlashWhite: AntiFlashWhite,
  disabledColor: DisabledColor,
  errorColor: ErrorColor,
  successColor: SuccessColor,
  placeholderColor: PlaceholderColor,
  borderColor: BorderColor,
  gray: Gray,
  cultured: Cultured,
  lightSilver: LightSilver,
  lavender: Lavender,
  ghost: Ghost,
  softPeach: SoftPeach,
  greyGoose: GreyGoose,
  balticSea: BalticSea,
  alabaster: Alabaster,
  desertStorm: DesertStorm,
  lightGrey: LightGrey,
  darkJungleGreen: DarkJungleGreen,
  blackCow: BlackCow,
  greyCloud: GreyCloud,
  rangoonGreen70Percent: RangoonGreen70Percent,
  dawn: Dawn,
  mercury: Mercury,
  silverChalice: SilverChalice,
  chineseBlack40Percent: ChineseBlack40Percent,
  heather: Heather,
  dawnPink: DawnPink,
  metallicSilver: MetallicSilver,
  davyGrey: DavyGrey
}