import { transitionsDelay } from '../../styles/basicStyles';
import styled, { css } from 'styled-components';

const setSize = (size?: number | number[], defaultSize = 1) => {
  if (Array.isArray(size)) {
    return css`
      height: ${size?.[0]}rem;
      width: ${size?.[1]}rem;
    `;
  }

  return css`
    height: ${size || defaultSize}rem;
    width: ${size || defaultSize}rem;
  `;
};

export const IconSvg = styled.div<{ size?: number | number[], color?: string, hoverColor?: string, noColor?: boolean}>`
  display: flex;

  ${({noColor, size, color}) => !noColor && 
    css`
       & svg {
        ${setSize(size)};

        & path[stroke] {
          transition: stroke ${transitionsDelay} linear;
          stroke: ${({ theme }) => color || theme.colors.black};
        }

        & path[fill] {
          transition: fill ${transitionsDelay} linear;
          fill: ${({ theme }) => color || theme.colors.black};
        }
      }

      ${({ hoverColor }) => !!hoverColor &&
        css`
          cursor: pointer;
          
          &:hover {
            & svg {
              & path[stroke] {
                stroke: ${hoverColor};
              }

              & path[fill] {
                fill: ${hoverColor};
              }
            }
          }
        `
      }
    `
  }

  
`;

