import { createContext } from 'react';

export interface LoaderContextProps {
  showLoader: boolean;
  setShowLoader: (showLoader: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextProps>({
  showLoader: true,
  setShowLoader: () => null
});

export default LoaderContext;
