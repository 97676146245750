import { Box } from '../../styles/basicStyles';
import { breakpoints } from '../../styles/breakpoints';
import styled from 'styled-components';

export const Circle1 = styled.div`
  position: absolute;
  top: -11.25rem;
  right: 1.25rem;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.50) 0%, rgba(100, 255, 227, 0.50) 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  @media ${breakpoints.md} {
    display: none;
  }
`;

export const Circle2 = styled.div`
  position: absolute;
  top: -0;
  right: -8.75rem;
  width: 17.5rem;
  height: 17.5rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(129, 108, 255, 0.50) 0%, rgba(129, 108, 255, 0.50) 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  @media ${breakpoints.md} {
    display: none;
  }
`;

export const SocialWrapper = styled(Box)`
  flex-wrap: wrap;
  
  & > div:first-child {
    padding-left: 0.25rem;
  }
`;

export const SealImage = styled.img`
  max-width: 7rem;
  height: auto;

  @media ${breakpoints.md} {
    margin-top: 2.125rem;
  }
`;
