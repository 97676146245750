import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
`;

export const Content = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center;
  height: 100vh; 
  width: 100%;
  background-color: #151516;

  & svg {
    width: 6.25rem;
    height: 7.188rem;
    animation: bounce 0.75s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 3.125rem, 0);
    }
  }
`;
