import { Content, Wrapper } from './styles';

const Loader = () => {
  return (
    <Wrapper>
      <Content>
        <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M37.9443 29.7024C37.9423 31.8171 37.1547 33.8548 35.7358 35.4164C34.3169 36.978 32.3689 37.9508 30.2736 38.1443C28.1782 38.3378 26.0865 37.738 24.4082 36.4625C22.7298 35.187 21.5859 33.3276 21.2005 31.2487C20.8151 29.1698 21.2159 27.0212 22.3245 25.2238C23.4331 23.4264 25.1696 22.1098 27.1937 21.532C29.2178 20.9541 31.3836 21.1566 33.2669 22.0998C35.1502 23.043 36.6152 24.659 37.3748 26.631C37.7518 27.6107 37.9449 28.6521 37.9443 29.7024V29.7024Z" fill="#816CFF"/>
          <path d="M46.699 46.8961L39.3412 39.4995C37.9484 40.8994 36.2731 41.9831 34.4284 42.6776C32.5837 43.372 30.6123 43.661 28.647 43.5252C26.6818 43.3894 24.7683 42.8319 23.0355 41.8902C21.3027 40.9485 19.7909 39.6446 18.6018 38.0662C17.4128 36.4878 16.5741 34.6716 16.1423 32.74C15.7106 30.8084 15.6957 28.8063 16.0988 26.8685C16.5019 24.9306 17.3135 23.1021 18.479 21.5061C19.6446 19.9101 21.1369 18.5836 22.8555 17.6161L23.1541 17.4532L23.4591 17.2967C26.0448 16.0055 28.9686 15.5627 31.8181 16.0307C34.6676 16.4987 37.299 17.8539 39.3412 19.9052L39.7678 19.4765L42.312 16.9195L50.4824 8.7084C47.3283 5.53861 43.501 3.12782 39.2874 1.65689C35.0739 0.185969 30.5836 -0.30689 26.1535 0.215293C21.7234 0.737477 17.4685 2.26114 13.708 4.67198C9.94744 7.08281 6.77901 10.3182 4.44037 14.1354C2.10174 17.9526 0.653625 22.2525 0.20469 26.7125C-0.244246 31.1725 0.317651 35.6768 1.84822 39.8874C3.37879 44.0979 5.83829 47.9055 9.04219 51.0244C12.2461 54.1433 16.1112 56.4924 20.3475 57.8957C20.5224 57.9557 20.7015 58.0136 20.8785 58.0693L21.3435 58.2065C28.5152 60.3134 36.2159 59.6 42.8836 56.2111V56.2111L42.9988 56.151C43.1267 56.0889 43.2526 56.0246 43.3784 55.956L43.6919 55.7867L43.8199 55.7159L43.9073 55.6666C46.3144 54.3258 48.5238 52.6544 50.4717 50.7006L50.1539 50.3598L46.699 46.8961Z" fill="white"/>
        </svg>
      </Content>
    </Wrapper>
  );
};

export default Loader;
