import { transitionsDelay } from '../../styles/basicStyles';
import { generateProps } from 'styled-gen';
import { ButtonProps, ButtonSizes } from './index';
import { Button } from '@mantine/core';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled(Button)<ButtonProps>`
  font-family: 'Graphie', sans-serif;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: background-color ${transitionsDelay} linear, border-color ${transitionsDelay} linear, opacity ${transitionsDelay} linear;
  appearance: none;
  line-height: 0;
  border-radius: 2.5rem;
  width: auto;
  height: auto;

  ${({ size }) => 
    size === 'xs' ?
      css`
        padding: 0.563rem 1.813rem;

        & svg {
          width: 1.188rem;
          height: 1.188rem;
        }
      `
    :
    size === 'sm' ?
      css`
        padding: 0.688rem 1.563rem;

        & svg {
          width: 1.063rem;
          height: 1.063rem;
        }
      `
    :
    size === 'lg' ?
      css`
        padding: 0.875rem 2.813rem;

        & svg {
          width: 1.375rem;
          height: 1.375rem;
        }
      `
    :
    size === 'xl' ?
      css`
        padding: 1.188rem 5.125rem;

        & svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      `
    :
      css`
        padding: 0.688rem 2.438rem;

        & svg {
          width: 1.188rem;
          height: 1.188rem;
        }
      `
  }

  ${({ variant, textColor, bgColor }) => 
    variant === 'secondary' ? 
      css`
        background-color: transparent !important;
        border: 0.063rem solid ${bgColor} !important;

        & span {
          transition: color ${transitionsDelay} linear;
          color: ${bgColor} !important;
        }

        & .icon {
          & svg path[fill] {
            fill: ${bgColor};
          }

          & svg path[stroke] {
            stroke: ${bgColor};
          }
        }

        &:hover {
          background-color: ${bgColor} !important;

          & .icon {
            & svg path[fill] {
              fill: ${textColor};
            }

            & svg path[stroke] {
              stroke: ${textColor};
            }
          }

          & span {
            color: ${textColor} !important;
          }
        }
      `
    :
    variant === 'tertiary' ? 
    css`
      background-color: transparent !important;
      border: 0.063rem solid ${textColor} !important;

      & span {
        transition: color ${transitionsDelay} linear;
        color: ${textColor} !important;
      }

      & .icon {
        & svg path[fill] {
          fill: ${textColor};
        }

        & svg path[stroke] {
          stroke: ${textColor};
        }
      }

      &:hover {
        background-color: ${bgColor} !important;
        border: 0.063rem solid ${bgColor} !important;

        & .icon {
          & svg path[fill] {
            fill: ${textColor};
          }

          & svg path[stroke] {
            stroke: ${textColor};
          }
        }

        & span {
          color: ${textColor} !important;
        }
      }
    `
  :
      css`
        background-color: ${bgColor} !important;
        border: 0.063rem solid ${bgColor} !important;

        & span {
          transition: color ${transitionsDelay} linear;
          color: ${textColor} !important;
        }

        & .icon {
          & svg path[fill] {
            fill: ${textColor};
          }

          & svg path[stroke] {
            stroke: ${textColor};
          }
        }

        &:hover {
          background-color: transparent !important;

          & .icon {
            & svg path[fill] {
              fill: ${bgColor};
            }

            & svg path[stroke] {
              stroke: ${bgColor};
            }
          }

          & span {
            color: ${bgColor} !important;
          }
        }
      `
  }

  &:not(:disabled) {
    &:hover {
      cursor: pointer;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-loading] {
    opacity: 0.7;

    &:before {
      display: none;
    }

    & .mantine-Button-leftIcon {
      margin-right: 0.375rem;

      & svg {
        stroke: ${({ variant, bgColor, textColor }) => variant === 'secondary' ? bgColor : textColor};

        ${({ size }) => 
          size === 'xs' ?
            css`
              width: 1.188rem !important;
              height: 1.188rem !important;
            `
          :
          size === 'sm' ?
            css`
              width: 1.063rem !important;
              height: 1.063rem !important;
            `
          :
          size === 'lg' ?
            css`
              width: 1.375rem !important;
              height: 1.375rem !important;
            `
          :
          size === 'xl' ?
            css`
              width: 1.5rem !important;
              height: 1.5rem !important;
            `
          :
            css`
              width: 1.188rem !important;
              height: 1.188rem !important;
            `
        }
      }
    }
  }

  & .icon {
    margin-left:  ${({ noIconMargin }) => noIconMargin ? 0 : '0.375rem'};

    & svg path[fill] {
      transition: fill ${transitionsDelay} linear;
    }

    & svg path[stroke] {
      transition: stroke ${transitionsDelay} linear;
    }
  }

  ${generateProps};
`;

export const ButtonText = styled.span<{ size: ButtonSizes, textColor?: string }>` 
  font-weight: 600;
  line-height: 1;
  color: ${({ textColor }) => textColor};

  ${({ size }) => 
    size === 'xs' ?
      css`
        font-size: 1.188rem;
      `
    :
    size === 'sm' ?
      css`
        font-size: 1.063rem;
      `
    :
    size === 'lg' ?
      css`
        font-size: 1.375rem;
      `
    :
    size === 'xl' ?
      css`
        font-size: 1.5rem;
      `
    :
      css`
        font-size: 1.188rem;
      `
  }
`;