import { transitionsDelay } from '../../styles/basicStyles';
import { breakpoints } from '../../styles/breakpoints';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 41.25rem;
  padding: 0 1.875rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${breakpoints.sm} {
    justify-content: center;
  }
`

export const ImageContent = styled.div`
  position: relative;
`;

export const Image1 = styled.img`
  @media ${breakpoints.sm} {
    max-width: 15.625rem;
  }

  @media ${breakpoints.xs} {
    max-width: 13.75rem;
  }
`;

export const Image2 = styled.img`
  position: absolute;
  bottom: -1.875rem;
  right: -2.5rem;
	animation: rotation 4s infinite linear;

  @media ${breakpoints.md} {
    max-width: 10rem;
    right: -1.25rem;
  }

  @media ${breakpoints.sm} {
    max-width: 8.125rem;
    right: -0.625rem;
    bottom: -1.25rem;
  }

  @media ${breakpoints.xs} {
    max-width: 6.875rem;
    right: -1.875rem;
  }
  
  @keyframes rotation {
    0% {
      transform: rotateY(0deg);
    }
    25% {
      transform: rotateY(179deg);
    }
    50% {
      transform: rotateY(359deg);
    }
    100% {
      transform: rotateY(359deg);
    }
  }
`;

export const Title = styled.h1`
  color: #231F20;
  font-family: 'Graphie', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5rem;
  margin-bottom: 0.625rem;

  @media ${breakpoints.md} {
    font-size: 3.125rem;
    line-height: 4.375rem;
  }

  @media ${breakpoints.sm} {
    font-size: 2.813rem;
    line-height: 3.75rem;
    text-align: center;
  }

  @media ${breakpoints.xs} {
    font-size: 2.5rem;
    line-height: 3.438rem;
  }
`;

export const Text = styled.h2`
  margin-bottom: 3.125rem;
  color: #231F20;
  font-family: 'Graphie', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;

  @media ${breakpoints.md} {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  @media ${breakpoints.sm} {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.875rem;
    text-align: center;
  }

  @media ${breakpoints.xs} {
    font-size: 0.938rem;
    line-height: 1.25rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ${breakpoints.sm} {
    justify-content: center;
  }
`;

export const Button = styled.div`
  border-radius: 0.5rem;
  background-color: #816CFF;
  border: 0.063rem solid #816CFF;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-family: 'Graphie', sans-serif;
  cursor: pointer;
  transition: color ${transitionsDelay} linear, background-color ${transitionsDelay} linear;
  font-size: 1.125rem;
  line-height: 1;

  &:hover {
    background-color: #ffffff;
    color: #816CFF;
  }

  @media ${breakpoints.sm} {
    font-size: 1rem;
  }
`;

