import "../styles/fonts.css";
import "../components/typography/styles.css";
import "nprogress/nprogress.css";
import type { AppProps } from "next/app";
import { AppContainer, BasicStyles, ViewContainer } from "../styles/basicStyles";
import { ThemeProvider } from "styled-components";
import { appWithTranslation } from "next-i18next";
import { MantineProvider } from "@mantine/core";
import { useContext, useEffect } from "react";
import { GetFooterInfo } from "../api/requests/base";
import { AnyObject } from "../models/generic";
import { FooterInfo } from "../models/global";
import { Notifications } from '@mantine/notifications';
import Router from "next/router";
import Head from "next/head";
import NProgress from "nprogress";
import Theme from "../styles/theme";
import Header from "../components/header";
import Footer from "../components/footer";
import NextI18nextConfig from "../../next-i18next.config";
import Context from "../context/context";
import LoaderContext from "../context/loader/loaderContext";
import cookies from "next-cookies";
import CookieBar from "../components/cookies";
import ServerErrorPage from '../views/serverError';
import Loader from "../components/loader";
import Script from "next/script";

Router.events.on("routeChangeStart", (_: any, { shallow }: any) => {
  if(!shallow) NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

interface InnerAppProps extends AppProps {
  appProps?: {
    footerInfo?: FooterInfo;
    acceptCookies?: boolean;
    success: boolean;
  };
}

const InnerApp = (props: InnerAppProps) => {
  const { Component, pageProps, appProps, router } = props;

  return (
    <AppContainer>
      <Header isBlack={router?.route?.includes("/404") || router?.route?.includes("/work/[slug]") ? true : false} />
      <ViewContainer id="main">
        <Component {...pageProps} />
        <Footer data={appProps?.footerInfo} />
      </ViewContainer>
      <CookieBar acceptCookies={appProps?.acceptCookies} />
    </AppContainer>
  );
};

const AppWrapper = (props: InnerAppProps) => {
  const { appProps } = props;
  const { showLoader, setShowLoader } = useContext(LoaderContext);

  useEffect(() => {
    if(showLoader) setShowLoader(false);
  }, []);

  return (
    <>
      {
        appProps?.success ?
        <>
          {showLoader && <Loader />}
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <ThemeProvider theme={Theme}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
              </Head>
              <InnerApp {...props} />
              <BasicStyles />
              <Notifications position="top-right" zIndex={1000} />
            </ThemeProvider>
          </MantineProvider>
        </>
        :
        <ServerErrorPage/>
      }
    </>
  );
};

const App = (props: AppProps) => {
  return (
    <>
      {
        process.env.NEXT_PUBLIC_USE_ANALYTICS === 'true' &&
        <>
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KDDLXCQ');
              `,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KDDLXCQ"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
              `,
            }}
          />
        </>
      }
      <Context>
        <AppWrapper {...props} />
      </Context>
    </>
  );
};

App.getInitialProps = async ({ ctx }: AnyObject) => {
  const { CODEPOINT_ACCEPT_COOKIES } = cookies(ctx);

  const { data, success } = await GetFooterInfo();

  return {
    appProps: {
      footerInfo: data,
      acceptCookies: CODEPOINT_ACCEPT_COOKIES,
      success: success
    }
  };
};

export default appWithTranslation(App, NextI18nextConfig);
