import { FC, Fragment, useEffect } from "react";
import { Block, Box, Image } from "../../styles/basicStyles";
import { MobileWrapper, MobileLink, MobileContent, Circle1, Circle2, Circle3 } from "./styles";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { items, ItemProps } from "./items";
import { Languages, LanguagesProps } from "../../utils/languages";
import { colors } from "../../styles/colors";
import Typography from "../typography";
import Icon from "../icon";
import Button from "../button";

interface Props {
  toggleLanguage: (value: string) => void;
  mobileMenu: boolean;
  toggleMenu: () => void;
  screenHeight: number;
}

export const Mobile: FC<Props> = ({
  toggleLanguage,
  mobileMenu,
  toggleMenu,
  screenHeight
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();
  const whiteNavbar = [
    "/work",
    "/blog",
    "/blog/[slug]",
    "/company/[slug]",
  ];

  useEffect(() => {
    if(mobileMenu) toggleMenu();
  }, [router.pathname]);

  const handleHeaderIconStyle = () => {
    if (whiteNavbar.includes(router.pathname)) {
      return colors.black;
    } else {
      return colors.white;
    }
  };

  return (
    <Box fJustify="flex-end" display={{ md: "none", xxs: "flex" }}>
      <Icon
        icon="outlined_menu"
        color={handleHeaderIconStyle()}
        size={2}
        hoverColor={colors.purple}
        onClick={toggleMenu}
      />
      <MobileWrapper open={mobileMenu} screenHeight={screenHeight}>
        <Block w="100%" position="relative">
          <Circle1 />
          <Circle2 />
          <Circle3 />
          <Block w="100%" padding="0 1.25rem">
            <Box h={5} fAlign="center" fJustify="space-between">
              <Image
                src="/assets/logo_black.svg"
                alt="logo"
                maxW={7.75}
                cursor="pointer"
                onClick={() => router.push("/")}
              />
              <Icon
                icon="outlined_x"
                color={colors.black}
                size={2}
                hoverColor={colors.purple}
                onClick={toggleMenu}
              />
            </Box>
            <MobileContent fDirection="column" fJustify="space-between" screenHeight={screenHeight}>
              <Box fDirection="column" fAlign="center">
                {items.map((elem: ItemProps, index: number) => (
                  <MobileLink
                    key={index}
                    href={elem.url}
                    prefetch={false}
                  >
                    <Typography variant="mobile-link">
                      {t(elem.name)}
                    </Typography>
                  </MobileLink>
                ))}
              </Box>
              <Box fDirection="column" fAlign="center">
                <Button
                  text={t("GET_IN_TOUCH")}
                  size="xs"
                  variant="primary"
                  textColor={colors.black}
                  bgColor={colors.lightBlue}
                  onClick={() => {
                    toggleMenu();
                    router.push("/start-project");
                  }}
                />
                <Box fAlign="center" fJustify="center" pt={2}>
                  {Languages.map((elem: LanguagesProps, index: number) => (
                    <Fragment key={index}>
                      <Typography
                        variant="mobile-language"
                        margin="0 1.25"
                        color={
                          language === elem.code
                            ? colors.chineseBlack
                            : colors.coolGrey
                        }
                        fWeight={language === elem.code ? 600 : 350}
                        hoverColor={colors.black}
                        onClick={() => toggleLanguage(elem.code)}
                      >
                        {elem.name}
                      </Typography>
                      {index !== Languages.length - 1 && (
                        <Typography
                          variant="mobile-language"
                          color={colors.antiFlashWhite}
                        >
                          |
                        </Typography>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </Box>
            </MobileContent>
          </Block>
        </Block>
      </MobileWrapper>
    </Box>
  );
};

export default Mobile;
