import { Menu, MenuItemProps } from '@mantine/core';
import { transitionsDelay } from '../../styles/basicStyles';
import styled, { css } from 'styled-components';

export const StyledDropdown = styled(Menu.Dropdown)`
  &.mantine-Menu-dropdown {
    background: ${({ theme }) => theme.colors.black};
    border-radius: 0.5rem;
    border: 0;
    padding: 0;
  }
`;

export const StyledItem = styled(Menu.Item)<MenuItemProps & { key: number, onClick?: () => void }>`
  padding: 0.813rem 1rem;
  background-color: transparent !important;
  cursor: ${({ onClick }) => !!onClick ? 'pointer' : 'initial'};

  & span {
    transition: color ${transitionsDelay} linear;
    color: rgba(255, 255, 255, 0.7);
  }

  ${({ onClick }) => !!onClick && 
    css`
      &:hover {
        & span {
          color: rgba(255, 255, 255, 1);
        }
      }
    `
  }
`;

export const StyledDivider = styled(Menu.Divider)<MenuItemProps & { key: number }>`
  border: 0.063rem solid rgba(255, 255, 255, 0.06);
  margin: 0;
`;
