import { FC, useEffect } from 'react';
import { colors } from '../../styles/colors';
import { Block, Box, Image, MainContainer } from '../../styles/basicStyles';
import { useTranslation } from 'next-i18next';
import { Circle1, Circle2, SealImage, SocialWrapper } from './styles';
import { FooterInfo } from '../../models/global';
import { useRouter } from 'next/router';
import Icon, { IconTypes } from '../icon';
import Typography from '../typography';
import Link from '../link';
import Button from '../button';

interface Props {
  data?: FooterInfo;
}

export const Footer: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Init();
    }
    return () => {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);

  const renderLink = (title: string, url: string, hasMargin = true) => {
    return (
      <Link mr={hasMargin ? 3.125 : 0} pb={0.5} href={url}>
        <Typography variant='footer_link' style={{ color: 'rgba(255, 255, 255, 0.8)'}}>{t(title)}</Typography>
      </Link>
    );
  };

  const renderSubLink = (title: string, url?: string, hasMargin = true) => {
    return (
      <Link mr={hasMargin ? 3.125 : 0} pb={0.5} href={url}>
        <Typography 
          fSize={{ sm: 1.063, xxs: 0.875}}
          lHeight={{ sm: 1.25, xxs: 1.063 }}
          fWeight={400} 
          style={{ color: 'rgba(255, 255, 255, 0.6)'}}
        >
          {t(title)}
        </Typography>
      </Link>
    );
  };

  const renderSocialLink = (icon: IconTypes, label: string, url?: string) => {
    return (
      <Link padding='0.5rem 1rem' href={url} target='_blank' rel="nofollow" aria-label={label}>
        <Icon icon={icon} color='rgba(255, 255, 255, 0.5)' hoverColor={colors.white} size={1.25} />
      </Link>
    );
  };

  return (
    <Box bgColor={colors.chineseBlack} position='relative' overflow='hidden'>
      <Circle1 />
      <Circle2 />
      <Block w='100%' position='relative' zIndex={2}>
        <MainContainer>
          <Box pt={5.375} fWrap='wrap'>
            <Box w={{ md: '50%', xxs: '100%' }} fDirection='column'>
              <Image src='/assets/small_logo_white.svg' alt='logo' />
              <Typography 
                as='div'
                fSize={{ sm: 1.75, xxs: 1.438 }}
                lHeight={{ sm: 2.5, xxs: 1.875 }}
                fWeight={400} 
                color={colors.white} 
                mt={1.25}
                maxW={18.5}
              >
                {t('FOOTER_SLOGAN')}
              </Typography>
            </Box>
            <Box w={{ md: '50%', xxs: '100%' }} mt={{ md: 0, xxs: 3.75 }} fDirection='column'>
              <Typography 
                fWeight={700}
                fSize={{ sm: 1.188, xxs: 1 }}
                lHeight={{ sm: 1.438, xxs: 1.188 }}
                color={colors.white}
              >
                {t('GET_IN_TOUCH')}
              </Typography>
              {
                !!data?.email &&
                <Link href={`mailto:${data?.email}`} mt={2}>
                  <Typography variant='footer_link' color={colors.lightBlue}>{data?.email}</Typography>
                </Link>
              }
              <Box 
                mt={1.5}
                fAlign={{ md: 'center', xxs: 'flex-start' }}
                fDirection={{ md: 'row', xxs: 'column' }}
              >
                <Button 
                  text={t('WRITE_US')}
                  variant='secondary'
                  size='xs'
                  textColor={colors.black}
                  bgColor={colors.lightBlue}
                  onClick={() => router.push('/start-project')}
                />
                {
                  !!data?.book_call &&
                  <>
                    <Typography 
                      fSize={{ sm: 1.188, xxs: 1.063 }}
                      lHeight={{ sm: 1.438, xxs: 1.25 }}
                      fWeight={600} 
                      color={colors.white} 
                      padding={{ md: '0 1.125rem', xxs: '1rem 0' }}
                    >
                      {t('OR')}
                    </Typography>
                    <Link display='flex' fAlign='center' href={data?.book_call} target='_blank'>
                      <Typography 
                        fSize={{ sm: 1.188, xxs: 1.063 }}
                        lHeight={{ sm: 1.438, xxs: 1.25 }}
                        fWeight={600} 
                        color={colors.white} 
                        pr={0.25}
                      >
                        {t('BOOK_CALL')}
                      </Typography>
                      <Icon icon='outlined_arrowUp' color={colors.white} style={{ transform: 'rotateZ(45deg)' }} size={1.25} />
                    </Link>
                  </>
                }
              </Box>
            </Box>
          </Box>
          <Box fWrap='wrap' pb={5.375} pt={2.75}>
            <Box 
              w={{ md: '50%', xxs: '100%' }} 
              fAlign={{ md: 'center', xxs: 'flex-start' }} 
              fDirection={{ md: 'row', xxs: 'column' }}
            >
              <Block 
                display='flex' 
                fAlign={{ md: 'center', xxs: 'flex-start' }} 
                fDirection='column'
              >
                <Typography 
                  fWeight={700}
                  fSize={1}
                  lHeight={1.188}
                  color={colors.white} 
                  pb={1} 
                  display={{ md: 'none', xxs: 'block' }}
                >
                  {t('FOLLOW_US')}
                </Typography>
                <SocialWrapper fAlign='center' mb={1.75} mt={{ md: 0, xxs: 0.75 }}>
                  {!!data?.facebook && renderSocialLink('social_facebook', 'Follow us on Facebook', data?.facebook)}
                  {!!data?.instagram && renderSocialLink('social_instagram', 'Follow us on Instagram', data?.instagram)}
                  {!!data?.dribbble && renderSocialLink('social_dribbble', 'Follow us on Dribbble', data?.dribbble)}
                  {!!data?.behance && renderSocialLink('social_behance', 'Follow us on Behance', data?.behance)}
                  {!!data?.linkedin && renderSocialLink('social_linkedin', 'Follow us on LinkedIn', data?.linkedin)}
                </SocialWrapper>
                <div 
                  className="clutch-widget" 
                  data-url="https://widget.clutch.co" 
                  data-darkbg="true" 
                  data-widget-type="2"
                  data-expandifr="true" 
                  data-height="62" 
                  data-clutchcompany-domain="codepoint.pt" 
                  data-primary-color="#816cff"
                >
                </div>
              </Block>
              <SealImage
                loading='lazy'
                src='/assets/seal.png' 
                alt='selo' 
              />
            </Box>
            <Box w={{ md: '50%', xxs: '100%' }} mt={{ md: 0, xxs: 2.5 }} fDirection='column'>
              <Typography 
                variant='footer-text'
                color={colors.white} 
                pb={2} 
                display={{ md: 'block', xxs: 'none' }}
              >
                {t('EXPLORE')}
              </Typography>
              <Box fAlign='center' fWrap='wrap' mb={1.063}>
                {renderLink('WORK', '/work')}
                {renderLink('PROCESS', '/process')}
                {renderLink('BLOG', '/blog')}
                {renderLink('CAREERS', '/careers', false)}
              </Box>
              <Box fAlign='center' fWrap='wrap'>
                {renderSubLink('PRIVACY_POLICY', '/privacy-policy')}
                {renderSubLink('COOKIES_POLICY', '/cookies-policy')}
                {renderSubLink('CLAIMS_AND_DISPUTES', '/complaints-and-disputes', false)}
              </Box>
            </Box>
          </Box>
        </MainContainer>
      </Block>
    </Box>
  );
};

export default Footer;
