import { FC } from "react";
import { Block, Box, Image } from "../../styles/basicStyles";
import { LanguageSelectedWrapper, LinkComponent } from "./styles";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { items, ItemProps } from "./items";
import { GetLanguage, Languages } from "../../utils/languages";
import { colors } from "../../styles/colors";
import Typography from "../typography";
import DropdownMenu from "../dropdownMenu";
import Icon from "../icon";
import Button from "../button";

interface Props {
  toggleLanguage: (value: string) => void;
}

export const Desktop: FC<Props> = ({ toggleLanguage }) => {
  const { t, i18n: { language } } = useTranslation();
  const router = useRouter();
  const greenButton = ["/work", "/blog", "/blog/[slug]", "/company/[slug]"];
  const whiteText50 = ["/", "/process", "/start-project", "/careers"];
  const greyText = ["/work", "/blog", "/blog/[slug]", "/company/[slug]"];
  const whiteLogo = ["/", "/process", "/start-project", "/careers"];
  const blackLogo = ["/work", "/blog", "/blog/[slug]", "/company/[slug]"];

  const handleButtonStyle = () => {
    return {
      textColor: greenButton.includes(router.pathname)
        ? colors.white
        : colors.black,
      bgColor: greenButton.includes(router.pathname)
        ? colors.black
        : colors.lightBlue,
    };
  };

  const handleLinkStyle = () => {
    switch (true) {
      case whiteText50.includes(router.pathname):
        return {
          textColor: colors.white,
          hoverColor: colors.white,
          hoverDotColor: colors.purple,
        };
      case greyText.includes(router.pathname):
        return {
          textColor: 'rgba(14, 14, 14, 0.7)',
          hoverColor: colors.black,
          hoverDotColor: colors.purple,
        };
      default:
        return {
          textColor: colors.white,
          hoverColor: colors.white,
          hoverDotColor: colors.purple,
        };
    }
  };

  const handleLogoStyle = () => {
    switch (true) {
      case whiteLogo.includes(router.pathname):
        return "/assets/logo_white.svg";
      case blackLogo.includes(router.pathname):
        return "/assets/logo_black.svg";
      default:
        return "/assets/logo_white.svg";
    }
  };

  const handleLanguageStyle = () => {
    return {
      textColor: greyText.includes(router.pathname)
        ? 'rgba(14, 14, 14, 0.7)'
        : colors.white,
      hoverColor: greyText.includes(router.pathname)
        ? colors.black
        : colors.lightBlue,
    };
  };

  return (
    <>
      <Image
        src={handleLogoStyle()}
        alt="logo"
        maxW={7.75}
        cursor="pointer"
        onClick={() => router.push("/")}
      />
      <Box
        fAlign="center"
        fJustify="flex-end"
        display={{ md: "flex", xxs: "none" }}
      >
        {
          items.map((elem: ItemProps, index: number) =>
            <LinkComponent
              key={index}
              href={elem.url}
              textColor={handleLinkStyle()?.textColor}
              hoverColor={handleLinkStyle()?.hoverColor}
              hoverDotColor={handleLinkStyle()?.hoverDotColor}
              isActive={router.pathname.includes(elem.url)}
              prefetch={false}
            >
              <Typography variant="header-link" style={{ userSelect: 'none', textDecoration: 'none' }}>{t(elem.name)}</Typography>
            </LinkComponent>
          )
        }
        <Block padding={{ lg: "0 1.438rem", xxs: "0 1rem" }}>
          <DropdownMenu
            render={
              <LanguageSelectedWrapper
                display="flex"
                fAlign="center"
                blackHover={greyText.includes(router.pathname) ? true : false}
              >
                <Typography
                  variant="header-language"
                  mr={0.313}
                  style={{ color: handleLanguageStyle()?.textColor }}
                >
                  {GetLanguage(language)?.name}
                </Typography>
                <Icon
                  icon="outlined_chevronDown"
                  size={1}
                  color={handleLanguageStyle()?.textColor}
                />
              </LanguageSelectedWrapper>
            }
            menuPosition="bottom-end"
            actions={Languages.filter((elem) => elem.code !== language).map(
              (elem) => ({
                type: "link",
                label: elem.name,
                onClick: () => toggleLanguage(elem.code),
              })
            )}
          />
        </Block>
        <Button
          text={t("GET_IN_TOUCH")}
          size="xs"
          variant="primary"
          textColor={handleButtonStyle()?.textColor}
          bgColor={handleButtonStyle()?.bgColor}
          onClick={() => router.push("/start-project")}
        />
      </Box>
    </>
  );
};

export default Desktop;
