/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonWrapper, ButtonText } from "./styles";
import { MouseEvent } from "react";
import { colors } from "../../styles/colors";
import Icon from "../icon";
import IconType from "../../../public/assets/icons/props";

export type ButtonVariants = "primary" | "secondary" | "tertiary" | undefined;

export type ButtonSizes = "xs" | "sm" | "md" | "lg" | "xl" | undefined;

export type ButtonTypes = "submit" | "button" | "reset" | undefined;

export interface ButtonProps {
  text?: string;
  icon?: IconType;
  size?: ButtonSizes;
  variant?: ButtonVariants | string;
  type?: ButtonTypes;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: MouseEvent) => void;
  textColor?: string;
  bgColor?: string;
  noIconMargin?: boolean;
}

const Button = ({
  text,
  icon,
  noIconMargin,
  ...forwardProps
}: ButtonProps & any) => {
  const { size, loading, textColor, bgColor } = forwardProps;
  const textColorFinal = textColor || colors.white;
  const bgColorFinal = bgColor || colors.black;

  return (
    <ButtonWrapper
      {...forwardProps}
      bgColor={bgColorFinal}
      noIconMargin={noIconMargin}
      aria-label={text || 'button'}
    >
      <ButtonText size={size} textColor={textColorFinal}>
        {text}
      </ButtonText>
      {!!icon && !loading && <Icon icon={icon} className="icon" />}
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  type: "button",
  size: "md",
  variant: "primary",
  disabled: false,
  loading: false,
  noIconMargin: false,
};

export default Button;
