import { FC, useState } from 'react';
import { Block, Box, MainContainer } from '../../styles/basicStyles';
import { CookieWrapper, CookieButton } from './styles';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { colors } from '../../styles/colors';
import Typography from '../typography';

interface Props {
  acceptCookies?: boolean;
}

const Cookies: FC<Props> = ({ acceptCookies }) => {
  const [cookieBar, setCookieBar] = useState(!acceptCookies);
  const { t } = useTranslation();
  const router = useRouter();

  const hideCookiesBar = () => {
    //Expires after 30 days
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime()+(30*24*60*60*1000));

    document.cookie = `CODEPOINT_ACCEPT_COOKIES=true; path=/; expires=${expiryDate.toUTCString()};`;
    setCookieBar(false);
  }

  return (
    <CookieWrapper cookieBar={cookieBar}>
      <MainContainer>
        <Box fWrap='wrap' fAlign='center'>
          <Block w={{ sm: '70%', xxs: '100%' }} pr={0.5}>
            <Typography fSize={0.875} lHeight={1.25} color='#ffffff'>
              {t('COOKIES_MESSAGE')}
            </Typography>
          </Block>
          <Box 
            fAlign='center' 
            fJustify={{ sm: 'flex-end', xxs: 'center' }}
            w={{ sm: '30%', xxs: '100%' }}
            mt={{ sm: 0, xxs: 0.5 }}
          >
            <CookieButton type='primary' onClick={hideCookiesBar}>
              <Typography fSize={{ md: 1, xxs: 0.875 }} lHeight={1} fWeight={600} color='#ffffff'>
                {t('COOKIES_ACCEPT')}
              </Typography>
            </CookieButton>
            <CookieButton type='secondary' onClick={() => router.push('/cookies-policy')}>
              <Typography fSize={{ md: 1, xxs: 0.875 }} lHeight={1} fWeight={600} color={colors.chineseBlack}>
                {t('COOKIES_KNOW_MORE')}
              </Typography>
            </CookieButton>
          </Box>
        </Box>
      </MainContainer>
    </CookieWrapper>
  );
};

export default Cookies;
